define("itarp-admin-dashboard/app", ["exports", "@ember/application", "ember-resolver", "ember-load-initializers", "itarp-admin-dashboard/config/environment"], function (_exports, _application, _emberResolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class App extends _application.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);

      _defineProperty(this, "engines", {
        'itarp-opportunity-designer': {
          dependencies: {
            services: [{
              store: 'opportunity-designer-store'
            }, {
              appRouter: 'router'
            }, {
              toast: 'toast'
            }, 'layout', 'micro-frontend', 'workflows', 'favorites', 'opportunity-reasons', 'markup-rate', 'stage-rules'],
            externalRoutes: {
              'schedule-interview': 'interviews.schedule-interview',
              'my-favorites': 'favorites'
            }
          }
        },
        'itarp-template-designer': {
          dependencies: {
            services: [{
              store: 'template-designer-store'
            }, {
              appRouter: 'router'
            }, {
              toast: 'toast'
            }, {
              router: 'router'
            }],
            externalRoutes: {
              unauthorized: 'unauthorized'
            }
          }
        },
        'itarp-workflow-designer': {
          dependencies: {
            services: ['layout', {
              store: 'workflow-designer-store'
            }, {
              toast: 'toast'
            }, {
              appRouter: 'router'
            }, {
              placeholders: 'placeholders'
            }, {
              router: 'router'
            }]
          }
        },
        'itarp-task-dashboard': {
          dependencies: {
            services: ['layout', {
              store: 'task-dashboard-store'
            }, {
              appRouter: 'router'
            }, 'adTokenService', 'micro-frontend', 'toast', 'workflows', 'favorites', 'router', 'opportunity-reasons', 'markup-rate', 'stage-rules'],
            externalRoutes: {
              opportunity: 'itarp-opportunity-designer.details.overview',
              'schedule-interview': 'interviews.schedule-interview',
              'opportunity-candidates': 'itarp-opportunity-designer.candidates',
              rosters: 'itarp-opportunity-designer.my-rosters',
              'shared-rosters': 'itarp-opportunity-designer.shared-rosters',
              interviews: 'interviews',
              opportunities: 'itarp-opportunity-designer.browse',
              'my-opportunities': 'itarp-opportunity-designer.my-opportunities',
              'new-opportunity': 'itarp-opportunity-designer.opportunity.job-details',
              'talent-cloud': 'talent-clouds.browse'
            }
          }
        },
        'itarp-business-application': {
          dependencies: {
            services: ['notifications', 'current-user', 'toast', 'layout'],
            externalRoutes: {
              expertiseApp: 'itarp-business-application.profile.expertise',
              'expert-opportunity': 'itarp-business-application.itarp-opportunity-ui.details',
              'expert-talentcloud': 'itarp-business-application.itarp-talentcloud-ui.details',
              unauthorized: 'unauthorized'
            }
          }
        },
        'itarp-opportunity-ui': {
          dependencies: {
            services: ['layout', 'workflows', 'toast'],
            externalRoutes: {
              expertise: 'itarp-business-application.profile.expertise',
              autofill: 'itarp-business-application.profile.autofill',
              'basic-info': 'itarp-business-application.profile.basic-info',
              'work-arrangement': 'itarp-business-application.profile.work-arrangement',
              'expert-opportunity': 'itarp-business-application.itarp-opportunity-ui.details',
              'dynamic-route': 'itarp-business-application.dynamic-route',
              unauthorized: 'unauthorized'
            }
          }
        },
        'itarp-talentcloud-ui': {
          dependencies: {
            services: ['layout', 'workflows', 'toast'],
            externalRoutes: {
              expertise: 'itarp-business-application.profile.expertise',
              autofill: 'itarp-business-application.profile.autofill',
              'basic-info': 'itarp-business-application.profile.basic-info',
              'work-arrangement': 'itarp-business-application.profile.work-arrangement',
              'expert-opportunity': 'itarp-business-application.itarp-opportunity-ui.details',
              'dynamic-route': 'itarp-business-application.dynamic-route',
              unauthorized: 'unauthorized'
            }
          }
        },
        'itarp-expert-preview': {
          dependencies: {
            services: ['layout', 'micro-frontend', 'store', 'toast', 'router', 'workflows', 'markup-rate'],
            externalRoutes: {
              'schedule-interview': 'interviews.schedule-interview',
              opportunity: 'itarp-opportunity-designer.details.overview'
            }
          }
        },
        'itarp-model-designer': {
          dependencies: {
            services: ['router']
          }
        }
      });
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});
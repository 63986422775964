define("itarp-admin-dashboard/components/sidebar", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <section
    class='dashboard-sidebar {{if @isCollapsed 'is-collapsed '}}'
    ...attributes
  >
    <div class='dashboard-sidebar__content'>
      <button
        data-test-collapse-toggle
        class='dashboard-sidebar__collapse'
        type='button'
        {{on 'click' (fn @collapse @isCollapsed)}}
      ></button>
      <div class='dashboard-sidebar__component'>
        <Sidebar::List @items={{@items}} @isCollapsed={{@isCollapsed}} />
      </div>
    </div>
  </section>
  
  */
  {
    "id": "rly/xXWD",
    "block": "[[[11,\"section\"],[16,0,[29,[\"dashboard-sidebar \",[52,[30,1],\"is-collapsed \"]]]],[17,2],[12],[1,\"\\n  \"],[10,0],[14,0,\"dashboard-sidebar__content\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"dashboard-sidebar__collapse\"],[24,4,\"button\"],[4,[38,1],[\"click\",[28,[37,2],[[30,3],[30,1]],null]],null],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"dashboard-sidebar__component\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@items\",\"@isCollapsed\"],[[30,4],[30,1]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@isCollapsed\",\"&attrs\",\"@collapse\",\"@items\"],false,[\"if\",\"on\",\"fn\",\"sidebar/list\"]]",
    "moduleName": "itarp-admin-dashboard/components/sidebar.hbs",
    "isStrictMode": false
  });

  class SidebarComponent extends _component2.default {}

  _exports.default = SidebarComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SidebarComponent);
});